function OurServices() {
  return (
    <section className="content-block">
      <div id="services--text" className="content-block--text">
        <h3>Our Services</h3>
        <ul>
          <li>
            Develop community outreach and communications plans during the
            construction phase of projects
          </li>
          <li>
            Maintain transparency to members of the public and their elected
            officials on construction updates
          </li>
          <li>
            Support project teams to convey information accessibly to
            communities on-site, online, and through plain language project
            communications
          </li>
          <li>Anticipate and mitigate issues before they arise</li>
          <li>Manage issues and respond to community inquiries</li>
        </ul>
      </div>
      <div id="services--image" className="content-block--image"></div>
    </section>
  );
}

export default OurServices;
