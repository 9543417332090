function ConnectWithUs() {
  return (
    <section className="content-block">
      <div id="contact--title">
        <h2 id="contact--title-text">Connect with Us</h2>
      </div>
      <div id="contact--hamilton" className="content-block--text">
        <h3 className="contact--heading">Hamilton</h3>
        <div className="contact--detail">
          <span className="material-icons">place</span>
          <div className="contact--address">
            <a
              href="https://goo.gl/maps/AXY1p6KTBVSuC1kD7"
              target="_blank"
              rel="noreferrer"
            >
              <p>614 Concession St</p>
              <p>Hamilton, ON L8V 1B5, Canada</p>
            </a>
          </div>
        </div>
        <div className="contact--detail">
          <span className="material-icons">phone</span>
          <p>
            <a href="tel:+19055270754">(905) 527-0754</a>
          </p>
        </div>
        <div className="contact--detail">
          <span className="material-icons">email</span>
          <p>
            <a href="mailto:info@lura.ca">info@lura.ca</a>
          </p>
        </div>
      </div>
      <div id="contact--toronto" className="content-block--text">
        <h3 className="contact--heading">Toronto</h3>
        <div className="contact--detail">
          <span className="material-icons">place</span>
          <div className="contact--address">
            <a
              href="https://goo.gl/maps/sRVgkEc99eFZBsn68"
              target="_blank"
              rel="noreferrer"
            >
              <p>424 - 192 Spadina Ave</p>
              <p>Toronto, ON M5T 2C2, Canada</p>
            </a>
          </div>
        </div>
        <div className="contact--detail">
          <span className="material-icons">phone</span>
          <p>
            <a href="tel:+14164103888">(416) 410-3888</a>
          </p>
        </div>
        <div className="contact--detail">
          <span className="material-icons">email</span>
          <p>
            <a href="mailto:info@lura.ca">info@lura.ca</a>
          </p>
        </div>
      </div>
    </section>
  );
}

export default ConnectWithUs;
