function ConstructionLiaison() {
  return (
    <section className="content-block">
      <div id="construction-liaison--text" className="content-block--text">
        <h3>Construction Liaison</h3>
        <p>
          At LURA we understand that as communities grow, construction can pose
          disruptions to existing residents and businesses, who must navigate
          their communities as projects are built. We also understand that
          project management need to devote their attention primarily ensuring
          project construction proceeds efficiently and safely.
        </p>
        <p>
          We bring experience assisting a variety of civil engineering and
          development industry partners with construction liaison teams to help
          projects run smoothly and minimize disruption to existing communities.
          We assist project management by acting as the main contact point to
          manage issues and interact with communities.
        </p>
        <p>
          From before the first shovel is in the ground to building occupancy,
          LURA provides an approachable and accessible contact able to serve as
          a two-way communicator between construction firms and community
          members.
        </p>
      </div>
      <div
        id="construction-liaison--image"
        className="content-block--image"
      ></div>
    </section>
  );
}

export default ConstructionLiaison;
