function ProjectCaseStudies() {
  return (
    <section className="content-block">
      <div id="recent-projects--text" className="content-block--text">
        <h3>Project Case Studies</h3>
        <ul>
          <li>
            <a
              href="case-studies/canada-square.pdf"
              target="_blank"
              title="Open PDF in new window"
            >
              Canada Square
              <span className="material-icons open-external">open_in_new</span>
            </a>
          </li>
          <li>
            <a
              href="case-studies/25-Ontario.pdf"
              target="_blank"
              title="Open PDF in new window"
            >
              25 Ontario Street
              <span className="material-icons open-external">open_in_new</span>
            </a>
          </li>
          <li>
            <a
              href="case-studies/highway-427.pdf"
              target="_blank"
              title="Open PDF in new window"
            >
              Highway 427 Expansion
              <span className="material-icons open-external">open_in_new</span>
            </a>
          </li>
          <li>
            <a
              href="case-studies/st-clair-place.pdf"
              target="_blank"
              title="Open PDF in new window"
            >
              St. Clair Place
              <span className="material-icons open-external">open_in_new</span>
            </a>
          </li>
          <li>
            <a
              href="case-studies/scarborough-subway-extension.pdf"
              target="_blank"
              title="Open PDF in new window"
            >
              Scarborough Subway Extension
              <span className="material-icons open-external">open_in_new</span>
            </a>
          </li>
        </ul>
      </div>
      <div id="recent-projects--image" className="content-block--image"></div>
    </section>
  );
}

export default ProjectCaseStudies;
