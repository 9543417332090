import Logo from "../images/logo.jpg";

function Header() {
  return (
    <header>
        <div id="hero"></div>
        <div id="header--logo-banner">
            <img id="header--logo-image" src={Logo} alt="LURA logo" />
            <h2 id="header--logo-text">Listen • Understand • Relate • Advance</h2>
        </div>
    </header>
  )
}

export default Header