import ConnectWithUs from "./ConnectWithUs";
import ConstructionLiaison from "./ConstructionLiaison";
import OurServices from "./OurServices";
import ProjectCaseStudies from "./ProjectCaseStudies";

function Main() {
  return (
    <main>
      <div id="main--content">
        <ConstructionLiaison />
        <OurServices />
        <ProjectCaseStudies />
        <ConnectWithUs />
      </div>
    </main>
  );
}

export default Main;
